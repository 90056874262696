import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Container from '@components/Container'
import Hero from '@components/Hero'
import PostList from '@components/PostList'
import Footer from '@components/Footer'
import * as S from '@styles/components/Archive'
import SEO from '@components/partials/SEO'
import { metaData } from '@fragments/meta'

export default class ProjectPlatform extends Component {
  // TODO: Inherit flyout usage from main archive
  constructor(props) {
    super(props)
    this.useFlyout = false
  }

  render() {
    const { data } = this.props
    const {
      allWordpressWpProject: { edges: projects },
      allWordpressWpPlatform: { edges: platforms },
      wordpressPage: page,
      wordpressWpPlatform: { yoast_meta: meta, ...platform },
    } = data

    page.acf.hero.type = 'basic'
    page.acf.hero.heading = platform.name

    return (
      <Container page={page}>
        <SEO {...meta} path={platform.path} />
        {page.acf.hero && <Hero {...page.acf.hero} {...page} />}
        <main>
          <S.PageWrapper>
            <S.Archive>
              <PostList
                type="project"
                posts={projects}
                categories={platforms}
                useFlyout={this.useFlyout}
              />
            </S.Archive>
          </S.PageWrapper>
        </main>
        <Footer />
      </Container>
    )
  }
}

export const pageQuery = graphql`
  query ProjectPlatformPage($wordpress_id: Int!) {
    allWordpressWpProject(
      # TODO: Make this work
      filter: { platform: { eq: $wordpress_id } }
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    wordpressWpPlatform(wordpress_id: { eq: $wordpress_id }) {
      name
      path
      yoast_meta {
        ...metaData
      }
    }
    allWordpressWpPlatform(filter: { count: { gt: 0 } }) {
      edges {
        node {
          id
          name
          slug
          path
        }
      }
    }
    wordpressPage(slug: { eq: "projects" }) {
      path
      acf {
        hero {
          ...HeroFragment
        }
      }
    }
  }
`
